<template>
  <g>
    <g id="g25" transform="rotate(90,-778,9588.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path27"/></g
    ><g id="g29" transform="rotate(90,-4155.5,6211)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path31"/></g
    ><g id="g33" transform="rotate(90,845.5,7965)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path35"/></g
    ><g id="g37" transform="rotate(90,-908.5,2964)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path39"/></g
    ><g id="g41" transform="rotate(90,-2532,4587.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path43"/></g
    ><g id="g45" transform="rotate(90,2469,6341.5)"
      ><path
        d="M 0,-60.5 C 33.41323,-60.5 60.5,-33.41323 60.5,0 60.5,33.41323 33.41323,60.5 0,60.5 -33.41323,60.5 -60.5,33.41323 -60.5,0 -60.5,-33.41323 -33.41323,-60.5 0,-60.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path47"/></g
    ><path
      d="m 9326,3537 v 6956"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path49"/><path
      d="m 9213.5,3424 c 62.6846,0 113.5,50.8157 113.5,113.5"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path51"/><path
      d="M 1652,3424 H 9213"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path53"/><g id="g55" transform="rotate(-90,2595,942.5)"
      ><path
        d="m 0,-113.5 c 62.68432,0 113.5,50.81568 113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path57"/></g
    ><path
      d="M 1539,10493 V 3537"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path59"/><g id="g61" transform="rotate(180,826.25,5246.75)"
      ><path
        d="m 0,-113.5 c 62.68432,0 113.5,50.81568 113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path63"/></g
    ><path
      d="M 9213,10606 H 1652"
      style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
      id="path65"/><g id="g67" transform="rotate(90,-640,9853.5)"
      ><path
        d="m 0,-113.5 c 62.68432,0 113.5,50.81568 113.5,113.5"
        style="fill:none;stroke:#000000;stroke-width:24;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
        id="path69"/></g
  ></g>
</template>
<script>
export default {
  name: "Dev260Bottom",
};
</script>
